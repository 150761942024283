import React, { useState } from 'react';
import Page from 'src/components/Page';
import InputBase from '@material-ui/core/InputBase';
import { alpha, styled } from '@mui/material/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import InputLabel from '@mui/material/InputLabel';
import {
  Container,
  Typography,
  Box,
  Grid,
  Popover,
  Button,
  Hidden
} from '@material-ui/core';
import Bar from 'src/components/Bar';
import TableData from './Table';
import BalanceTab from 'src/components/BalanceTab';
import axios from 'src/axios';
import { useAuthState } from 'src/context';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from 'src/components/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import { formatAmount } from 'src/utils/helperFunctions';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: '50px 0px'
  },
  button: {
    textTransform: 'none',
    display: 'block',
    fontSize: 14,
    height: 40,
    borderRadius: 10,
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)',
    '&:hover': {
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 7%)'
    }
  },
  buttonOne: {
    textTransform: 'none',
    height: 40,
    borderTop: '0.5px solid #ced4da',
    borderLeft: '0.5px solid #ced4da',
    borderRight: '0.5px solid #ced4da',
    borderBottom: 'none',
    fontSize: 18,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: '19rem',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)'
    },
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`
    }
  },

  buttonTwo: {
    textTransform: 'none',
    height: 40,
    border: '.5px solid #ced4da',
    fontSize: 18,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    width: '19rem',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)'
    },
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`
    }
  },
  payoutDialog: {
    width: 350,
    height: 400
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: '#ffffff59' //rgba(0,0,30,0.2)
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 10,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '15px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#768095'
    }
  }
}));

const PayoutsView = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openI = Boolean(anchorEl);
  const classes = useStyles();
  const id = openI ? 'simple-popover' : undefined;
  const [amount, setAmount] = useState(null);
  const [bankAmount, setBankAmount] = useState(null);
  const [phone, setPhone] = useState(null);
  const [pin, setPin] = useState();
  const { token } = useAuthState();
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState(false);
  const [snack, setSnack] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [verifyerror, setVerifyError] = useState(false);
  const [verifyresponse, setVerifyResponse] = useState(null);
  const [bresponse, setBresponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isBLoading, setIsBLoading] = useState(false);
  const [method, setMethod] = useState(true);
  const [password, setPassword] = useState(null);
  const navigate = useNavigate();

  const [openverify, setOpenVerify] = React.useState(false);

  const handleOpen = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAmount('');
    // setCharges('');
    setPin('');
    setPhone('');
    setAnchorEl(null);
    setError(false);
  };
  const handleAmountChange = ({ target }) => {
    const { value } = target;
    setAmount(value);
  };
  const [bankData, setBankData] = useState('');
  React.useEffect(() => {
    axios({
      method: 'get',
      url: 'https://api.fapshi.com/cashout/bank-details',
      headers: { 'x-access-token': token }
    }).then(res => {
      setBankData(res.data);
    });
  }, []);

  const handlePayout = () => {
    setIsLoading(true);
    const val = {
      amount: parseInt(amount),
      phone,
      pin,
      operator: 'Mobile money'
    };
    axios({
      method: 'post',
      data: val,
      url: 'https://api.fapshi.com/cashout/mtn',
      headers: { 'x-access-token': token }
    })
      .then(() => {
        setSnack(true);
        setIsLoading(false);
        setAmount('');
        setPin('');
        setPhone('');
        setAnchorEl(null);
        setDisableDialog(false);
      })
      .catch(err => {
        setIsLoading(false);
        setError(true);
        setEresponse(err.response?.data.message);
      });
  };

  const handleBankPayout = () => {
    setIsBLoading(true);
    axios({
      method: 'post',
      data: { amount: parseInt(bankAmount) },
      url: 'https://api.fapshi.com/cashout/bank',
      headers: { 'x-access-token': token }
    })
      .then(() => {
        setSnack(true);
        setIsBLoading(false);
        setBankAmount('');
        setAmount('');
        setPin('');
        setPhone('');
        setAnchorEl(null);
      })
      .catch(err => {
        setIsBLoading(false);
        setErrors(true);
        setBresponse(err.response?.data.message);
      });
  };
  const handleSnackClose = () => {
    setSnack(false);
  };

  const [openDisableDialog, setDisableDialog] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDialogClose = () => {
    setAnchorEl(null);
  };

  const handleDisableClose = () => {
    setDisableDialog(false);
    setError(false);
  };

  const handleDialogOpen = () => {
    setDisableDialog(true);
  };

  const verifyAccount = () => {
    setIsLoading(true);
    axios({
      method: 'post',
      data: { password },
      url: 'https://api.fapshi.com/user/password',
      headers: { 'x-access-token': token }
    })
      .then(() => {
        setError(false);
        setIsLoading(false);
        navigate('/app/bank-account/add', {
          replace: false,
          state: { pass: password }
        });
      })
      .catch(err => {
        setIsLoading(false);
        setVerifyError(true);
        setVerifyResponse(err.response?.data.message);
      });
  };

  const handleVerifyClose = () => {
    setError(false);
    setAmount('');
    setOpenVerify(false);
  };

  const handleVerifyOpen = () => {
    setOpenVerify(true);
  };

  return (
    <Page title="Payouts">
      <Container maxWidth={false}>
        <Grid container>
          <Hidden mdDown>
            <Grid item xs={12} sm={12} lg={3} xl={2}>
              <BalanceTab val="payouts" />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={12} lg={9} xl={10}>
            <Bar
              title="Payouts"
              btnText="Make a withdrawal"
              onClick={handleOpen}
            />
            <Snackbar
              duration={6000}
              close={handleSnackClose}
              data="Payout successful"
              open={snack}
            />
            <Box>
              <Popover
                style={{ marginTop: 5 }}
                id={id}
                open={openI}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
              >
                <DialogContent
                  className={classes.payoutDialog}
                  style={{ height: method ? 400 : 440 }}
                >
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input-item"
                    style={{
                      color: '#3e4040',
                      fontWeight: 700,
                      fontSize: 22,
                      marginTop: 10
                    }}
                  >
                    <Typography variant="p">Select operator</Typography>
                  </InputLabel>
                  <Button
                    onClick={() => {
                      setMethod(true);
                    }}
                    className={classes.buttonOne}
                    style={{
                      backgroundColor: method ? '#3f51b5' : '#fff',
                      color: method ? '#fff' : '#3e4040'
                    }}
                  >
                    Mobile Money
                  </Button>
                  <Button
                    onClick={() => {
                      setMethod(false);
                    }}
                    style={{
                      backgroundColor: !method ? '#3f51b5' : '#fff',
                      color: !method ? '#fff' : '#3e4040'
                    }}
                    className={classes.buttonTwo}
                  >
                    Bank transfer
                  </Button>
                  {method ? (
                    <Box>
                      <InputLabel
                        shrink
                        htmlFor="bootstrap-input-item"
                        style={{
                          color: '#3e4040',
                          fontWeight: 700,
                          fontSize: 22,
                          marginTop: 10
                        }}
                      >
                        <Typography variant="p">Amount</Typography>
                      </InputLabel>
                      <BootstrapInput
                        name="item"
                        id="item"
                        type="number"
                        InputLabelProps={{
                          shrink: true
                        }}
                        variant="outlined"
                        placeholder="Enter amount"
                        size="small"
                        onChange={handleAmountChange}
                        value={amount}
                        style={{ width: '100%' }}
                      />
                      <Box style={{ display: 'block' }}>
                        <Box>
                          <InputLabel
                            shrink
                            htmlFor="bootstrap-input-item"
                            style={{
                              color: '#3e4040',
                              fontWeight: 700,
                              fontSize: 22,
                              marginTop: 10
                            }}
                          >
                            <Typography variant="p">Phone</Typography>
                          </InputLabel>
                          <BootstrapInput
                            name="phone"
                            id="phone"
                            type="number"
                            InputLabelProps={{
                              shrink: true
                            }}
                            variant="outlined"
                            placeholder="Enter phone"
                            size="small"
                            onChange={e => {
                              setPhone(e.target.value);
                            }}
                            value={phone}
                            style={{ width: '100%' }}
                          />
                        </Box>
                        <Box>
                          <InputLabel
                            shrink
                            htmlFor="bootstrap-input-item"
                            style={{
                              color: '#3e4040',
                              fontWeight: 700,
                              fontSize: 22,
                              marginTop: 10
                            }}
                          >
                            <Typography variant="p">Pin</Typography>
                          </InputLabel>
                          <BootstrapInput
                            name="item"
                            id="item"
                            type="password"
                            text="number"
                            InputLabelProps={{
                              shrink: true
                            }}
                            variant="outlined"
                            placeholder="Pin"
                            size="small"
                            onChange={e => setPin(e.target.value)}
                            value={pin}
                            style={{ width: '100%' }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  ) : bankData.bankName ? (
                    <Box>
                      {errors && (
                        <Box style={{ marginTop: 20 }}>
                          <Typography
                            variant="p"
                            style={{
                              color: '#eb6558',
                              fontSize: 16,
                              fontWeight: 900
                            }}
                          >
                            {bresponse}
                          </Typography>
                        </Box>
                      )}
                      <Box style={{ marginTop: 20 }}>
                        <Typography
                          variant="p"
                          style={{
                            fontSize: 18,
                            fontWeight: 400
                          }}
                        >
                          Bank payouts take at most 72hrs to be completed.
                        </Typography>
                      </Box>
                      <InputLabel
                        shrink
                        htmlFor="bootstrap-input-item"
                        style={{
                          color: '#3e4040',
                          fontWeight: 500,
                          fontSize: 24,
                          marginTop: 20,
                          marginBottom: -15
                        }}
                      >
                        <Typography variant="p">Bank name</Typography>
                      </InputLabel>
                      <Typography
                        variant="p"
                        style={{
                          fontSize: 20,
                          fontWeight: 500
                        }}
                      >
                        {bankData.bankName}
                      </Typography>
                      <InputLabel
                        shrink
                        htmlFor="bootstrap-input-item"
                        style={{
                          color: '#3e4040',
                          fontWeight: 500,
                          fontSize: 24,
                          marginTop: 10,
                          marginBottom: -15
                        }}
                      >
                        <Typography variant="p">Account number</Typography>
                      </InputLabel>
                      <Typography
                        variant="p"
                        style={{ fontSize: 22, fontWeight: 500 }}
                      >
                        {bankData.accountNum}
                      </Typography>
                      <InputLabel
                        shrink
                        htmlFor="bootstrap-input-item"
                        style={{
                          color: '#3e4040',
                          fontWeight: 500,
                          fontSize: 24,
                          marginTop: 10,
                          marginBottom: -10
                        }}
                      >
                        <Typography variant="p">Amount</Typography>
                      </InputLabel>
                      <BootstrapInput
                        name="item"
                        id="item"
                        type="number"
                        InputLabelProps={{
                          shrink: true
                        }}
                        variant="outlined"
                        placeholder="Enter amount"
                        size="small"
                        onChange={e => {
                          setBankAmount(e.target.value);
                        }}
                        value={bankAmount}
                        style={{ width: '100%' }}
                      />
                    </Box>
                  ) : (
                    <Box style={{ marginTop: 20 }}>
                      <Typography
                        variant="p"
                        style={{
                          fontSize: 16,
                          fontWeight: 700
                        }}
                      >
                        Bank payout account not set.
                      </Typography>
                      <Box onClick={handleVerifyOpen}>
                        <a
                          style={{
                            textDecoration: 'underline',
                            color: '#3f51b5'
                          }}
                        >
                          Set bank account
                        </a>
                      </Box>
                    </Box>
                  )}
                </DialogContent>
                <DialogActions
                  style={{
                    borderTop: '1px solid #f4f4f5',
                    padding: 15
                  }}
                >
                  {method ? (
                    <>
                      <Button
                        size="small"
                        color="primary"
                        variant="outlined"
                        style={{ textTransform: 'none' }}
                        onClick={handleClose}
                        className={classes.button}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        disabled={!amount || !phone || !pin ? true : false}
                        style={{ textTransform: 'none' }}
                        onClick={handleDialogOpen}
                        className={classes.button}
                      >
                        Confirm
                      </Button>
                    </>
                  ) : (
                    <>
                      {' '}
                      <Button
                        size="small"
                        color="primary"
                        variant="outlined"
                        style={{ textTransform: 'none' }}
                        onClick={handleClose}
                        className={classes.button}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        disabled={!bankAmount ? true : false}
                        style={{ textTransform: 'none' }}
                        onClick={handleBankPayout}
                        className={classes.button}
                      >
                        {isBLoading ? (
                          <CircularProgress size="14px" />
                        ) : (
                          'Confirm'
                        )}
                      </Button>
                    </>
                  )}
                </DialogActions>
              </Popover>
              <Box>
                <Dialog
                  BackdropProps={{
                    classes: {
                      root: classes.backDrop
                    }
                  }}
                  open={openverify}
                  aria-labelledby="form-dialog-title"
                  className={classes.box}
                >
                  <DialogTitle
                    id="form-dialog-title"
                    style={{
                      borderBottom: '1px solid rgb(229 231 243)',
                      padding: 10
                    }}
                  >
                    <Typography
                      style={{
                        color: '#3e4040',
                        fontWeight: 700,
                        fontSize: 20,
                        marginLeft: 10
                      }}
                      variant="h6"
                    >
                      Verification required
                    </Typography>
                  </DialogTitle>

                  <DialogContent>
                    {verifyerror && (
                      <Typography
                        variant="p"
                        style={{
                          color: '#eb6558',
                          fontWeight: 700,
                          fontSize: 16
                        }}
                      >
                        {verifyresponse}
                      </Typography>
                    )}
                    <Box style={{ marginBottom: 15 }}>
                      <Typography
                        style={{
                          color: '#3e4040',
                          fontWeight: 400,
                          fontSize: 16
                        }}
                        variant="h6"
                      >
                        To access certain parts of your dashboard, an extra step
                        of Verification is required.
                      </Typography>
                    </Box>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input-password"
                      style={{
                        color: '#3e4040',
                        fontWeight: 700,
                        fontSize: 22
                      }}
                    >
                      <Typography variant="p">Enter your password</Typography>
                    </InputLabel>
                    <BootstrapInput
                      name="password"
                      id="password"
                      type="password"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      placeholder="Enter password"
                      size="small"
                      onChange={e => setPassword(e.target.value)}
                      value={password}
                    />
                  </DialogContent>
                  <DialogActions
                    style={{
                      borderTop: '1px solid rgb(229 231 243)',
                      padding: 10
                    }}
                  >
                    <Button
                      onClick={handleVerifyClose}
                      color="primary"
                      variant="outlined"
                      size="small"
                      className={classes.button}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      size="small"
                      onClick={verifyAccount}
                      className={classes.button}
                    >
                      {isLoading ? <CircularProgress size="14px" /> : 'Verify'}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </Box>
            <TableData />
          </Grid>
        </Grid>
        <Dialog
          fullScreen={fullScreen}
          open={openDisableDialog}
          onClose={handleDialogClose}
          aria-labelledby="responsive-dialog-title"
          BackdropProps={{
            classes: {
              root: classes.backDrop
            }
          }}
        >
          <DialogTitle
            style={{
              borderBottom: '1px solid rgb(229 231 243)',
              padding: 10,
              fontSize: '16px'
            }}
            id="responsive-dialog-title"
          >
            <Typography variant="h3">Confirm payout</Typography>
          </DialogTitle>
          <DialogContent>
            {error && (
              <Typography
                variant="p"
                style={{
                  color: '#eb6558',
                  fontSize: 16,
                  fontWeight: 900
                }}
              >
                {eresponse}
              </Typography>
            )}
            <DialogContentText>
              <Typography variant="p">
                You have initiated a payout of{' '}
                <b>{formatAmount(amount)} FCFA</b> to the following Mobile Money
                account number: <b>{phone}</b>. Select "Yes, Send" to confirm
                this payout or "Cancel" to cancel transaction.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions
            style={{
              borderTop: ' 1px solid rgb(239 239 239)',
              padding: 10
            }}
          >
            <Button
              autoFocus
              onClick={handleDisableClose}
              color="primary"
              className={classes.button}
              variant="outlined"
              size="small"
            >
              <Typography variant="h6">Cancel</Typography>
            </Button>
            <Button
              className={classes.button}
              color="primary"
              autoFocus
              variant="contained"
              size="small"
              onClick={handlePayout}
            >
              <Typography variant="h6" style={{ color: '#ffffff' }}>
                {isLoading ? <CircularProgress size="14px" /> : 'Yes, Send'}
              </Typography>
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
};

export default PayoutsView;
