import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/settings/SettingsView/Profile';
import TransactionsListView from 'src/views/transactions/TransactionsListView';
import TransferListView from 'src/views/transfers/TransferListView';
import BulkPayments from 'src/views/Bulk';
import Verify from 'src/views/Bulk/Verify';
import Confirm from 'src/views/Bulk/Confirm';
import View from 'src/views/Bulk/View';
import EditVerify from 'src/views/Bulk/Verify/Edit';
import EditConfirm from 'src/views/Bulk/Confirm/Edit';
import AddBulkPayments from 'src/views/Bulk/Add';
import EditBulkPayments from 'src/views/Bulk/Edit';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import ServiceListView from 'src/views/service/ServiceListView';
import RegisterView from 'src/views/auth/RegisterView';
import CodeVerification from 'src/views/auth/CodeVerification';
import Success from 'src/views/auth/Success';
import ForgotPassword from 'src/views/auth/ForgotPassword';
import NewPassword from 'src/views/auth/NewPassword';
import TwoStep from 'src/views/auth/TwoStep';
import ResendCode from 'src/views/auth/ResendCode';
import SettingsView from 'src/views/settings/SettingsView';
import PaymentsView from 'src/views/payments/PaymentsView';
import PaymentsConfirmation from 'src/views/payments/PaymentsView/Confirmation';
import DonationConfirmation from 'src/views/payments/PaymentsView/LinkConfirmation';
import InvoiceConfirmation from 'src/views/payments/PaymentsView/InvoiceConfirmation';
import TopupsView from 'src/views/topups/TopupsView';
import PayoutsView from 'src/views/payouts/PayoutsView';
import Addbank from 'src/views/payouts/Addbank';
import ProductsView from 'src/views/products/ProductsView';
import DisputesView from 'src/views/disputes/DisputesView';
import SingleDisputeView from 'src/views/disputes/DisputesView/SingleDispute';
import Invoices from 'src/views/Invoices';
import CreateInvoice from 'src/views/Invoices/Create';
import DuplicateInvoice from 'src/views/Invoices/Duplicate';
import SimpleDuplicate from 'src/views/Invoices/SimpleDuplicate';
import SimpleInvoice from 'src/views/Invoices/SimpleInvoice';
import EditInvoice from 'src/views/Invoices/Edit';
import InvoiceView from 'src/views/Invoices/InvoiceView';
import EditSimpleInvoice from 'src/views/Invoices/EditSimple';
import { BalancesListView } from 'src/views/balances/ BalancesListView';
import AddProduct from 'src/views/products/ProductsView/AddProduct';
import ViewProduct from 'src/views/products/ProductsView/ViewProduct';
import Store from 'src/views/products/ProductsView/Stores';
import Orders from 'src/views/products/ProductsView/Orders';
import OrderDetails from 'src/views/products/ProductsView/OrderDetails';
import ViewStore from 'src/views/stores/index';
import ViewProductWithId from 'src/views/products/ProductsView/ViewProductWithId';
import EditProduct from 'src/views/products/ProductsView/EditProduct';
import Structure from 'src/views/Activate/ ActivateView/Structure';
import Details from 'src/views/Activate/ ActivateView/Details';
import Fulfilment from 'src/views/Activate/ ActivateView/Fulfilment';
import Legal from 'src/views/Activate/ ActivateView/Legal';
import Confirmation from 'src/views/Activate/ ActivateView/Confirmation';
import LegalVendor from 'src/views/Activate/ ActivateView/LegalVendor';
import VendorConfirmation from 'src/views/Activate/ ActivateView/VendorConfirmation';
import Payments from 'src/views/Activate/ ActivateView/Payments';
import Representative from 'src/views/Activate/ ActivateView/Representative';
import Vendor from 'src/views/Activate/ ActivateView/Vendor';
import DonationView from 'src/views/links/LinkView';
import SimpleLink from 'src/views/links/LinkView/Simple';
import DonationLink from 'src/views/links/LinkView/Donation';
import VariableLink from 'src/views/links/LinkView/Variable';
import EditVariableLink from 'src/views/links/LinkView/VariableEdit';
import SimpleEdit from 'src/views/links/LinkView/SimpleEdit';
import DonationEdit from 'src/views/links/LinkView/DonationEdit';
import SimpleLinkView from 'src/views/links/LinkView/LinkView';
import DonationLinkView from 'src/views/links/LinkView/DonationView';
import DataIndex from 'src/views/links/LinkView/DataIndex';
import Downloads from 'src/views/links/LinkView/Download';
import Events from 'src/views/events';
import CreateEvent from 'src/views/events/CreateEvent';
import Bookings from 'src/views/events/Bookings';
import EditEvent from 'src/views/events/EditEvent';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    isPrivate: true,
    children: [
      { path: 'account', element: <AccountView /> },
      { path: 'activate/structure', element: <Structure /> },
      { path: 'activate/legal', element: <Legal /> },
      { path: 'activate/fulfilment', element: <Fulfilment /> },
      { path: 'activate/legalities', element: <Legal /> },
      { path: 'activate/business-details', element: <Details /> },
      { path: 'activate/vendor', element: <Vendor /> },
      { path: 'activate/vendor/legal', element: <LegalVendor /> },
      { path: 'activate/vendor/confirmation', element: <VendorConfirmation /> },
      { path: 'activate/representative', element: <Representative /> },
      { path: 'activate/payment-details', element: <Payments /> },
      { path: 'activate/confirmation', element: <Confirmation /> },
      { path: 'transactions', element: <TransactionsListView /> },
      { path: 'transfers', element: <TransferListView /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'services', element: <ServiceListView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: 'documentation', element: <AccountView /> },
      { path: 'payment-link', element: <DonationView /> },
      { path: 'payment-link/details', element: <DataIndex /> },
      { path: 'payment-link/simple', element: <SimpleLink /> },
      { path: 'payment-link/simple/edit', element: <SimpleEdit /> },
      { path: 'payment-link/donation', element: <DonationLink /> },
      { path: 'payment-link/donation/edit', element: <DonationEdit /> },
      { path: 'payment-link/variable', element: <VariableLink /> },
      { path: 'payment-link/variable/edit', element: <EditVariableLink /> },
      { path: 'payments', element: <PaymentsView /> },
      { path: 'Balances', element: <BalancesListView /> },
      { path: 'topups', element: <TopupsView /> },
      { path: 'payouts', element: <PayoutsView /> },
      { path: 'disputes', element: <DisputesView /> },
      { path: 'disputes/details', element: <SingleDisputeView /> },
      { path: 'products', element: <ProductsView /> },
      { path: 'store', element: <Store /> },
      { path: 'store/orders', element: <Orders /> },
      { path: 'store/order-details', element: <OrderDetails /> },
      { path: 'payments', element: <PaymentsView /> },
      { path: 'invoices', element: <Invoices /> },
      { path: 'invoices/create/simple', element: <SimpleInvoice /> },
      { path: 'invoices/create/advanced', element: <CreateInvoice /> },
      { path: 'invoices/edit/advanced', element: <EditInvoice /> },
      { path: 'invoices/edit/simple', element: <EditSimpleInvoice /> },
      { path: 'bank-account/add', element: <Addbank /> },
      { path: 'bulk-payments', element: <BulkPayments /> },
      { path: 'bulk-payments/add', element: <AddBulkPayments /> },
      { path: 'bulk-payments/edit', element: <EditBulkPayments /> },
      { path: 'bulk-payments/verify', element: <Verify /> },
      { path: 'bulk-payments/confirm', element: <Confirm /> },
      { path: 'bulk-payments/view', element: <View /> },
      { path: 'bulk-payments/edit/verify', element: <EditVerify /> },
      { path: 'bulk-payments/edit/confirm', element: <EditConfirm /> },
      {
        path: 'invoices/duplicate/advanced',
        element: <DuplicateInvoice />
      },
      {
        path: 'invoices/duplicate/simple',
        element: <SimpleDuplicate />
      },
      { path: 'payments/view', element: <PaymentsConfirmation /> },
      { path: 'donations/view', element: <DonationConfirmation /> },
      { path: 'invoices/view', element: <InvoiceConfirmation /> },
      { path: 'products/view-product', element: <ViewProduct /> },
      { path: 'products/add-product', element: <AddProduct /> },
      { path: 'products/edit-product', element: <EditProduct /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: 'link/:id', element: <SimpleLinkView /> },
      { path: 'donation/:id', element: <DonationLinkView /> },
      { path: 'product/:id', element: <ViewProductWithId /> },
      { path: 'invoices/:id', element: <InvoiceView /> },
      { path: 'events', element: <Events /> },
      { path: 'events/create-event', element: <CreateEvent /> },
      { path: 'events/edit-event', element: <EditEvent /> },
      { path: 'events/bookings', element: <Bookings /> }
    ]
  },
  {
    path: 'product',
    element: <MainLayout />,
    isPrivate: true,
    children: [{ path: 'create', element: <AddProduct /> }]
  },
  {
    path: 'store',
    element: <MainLayout />,
    isPrivate: false,
    children: [{ path: 'view', element: <ViewStore /> }]
  },
  {
    path: '/',
    element: <MainLayout />,
    isPrivate: false,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'verify-email', element: <CodeVerification /> },
      { path: 'forgot-password', element: <ForgotPassword /> },
      { path: 'new-password', element: <NewPassword /> },
      { path: 'two-step-verification', element: <TwoStep /> },
      { path: 'resend-code', element: <ResendCode /> },
      { path: 'register', element: <RegisterView /> },
      { path: 'verify/success', element: <Success /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: 'payment-link/details/download', element: <Downloads /> }
    ]
  }
];

export default routes;
