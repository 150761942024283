const texts = {
  // Menu
  viewprofile: 'View Profile',
  home: 'Home',
  balance: 'Balance',
  payouts: 'Payouts',
  paymentlinks: 'Payment Links',
  invoices: 'Invoices',
  settings: 'Settings',
  resources: 'Resources',
  contactsupport: 'Contact Support',
  guides: 'Guides',
  aboutfapshi: 'About Fapshi',
  logout: 'Logout',

  // Home Screen
  hello: 'Hello',
  yourbalance: 'Your Balance',
  makewithdrawal: 'Make a withdrawal',
  paymentlink: 'Payment link',
  invoice: 'Invoice',
  collectmoney: 'Collect money with ease',
  balances: 'Balances',
  checkbalances: 'Check your balances',
  invoicing: 'Invoicing',
  createbrandedinvoice: 'Create branded invoices',
  activateprompt1: 'You have not activated your account!!!',
  activateprompt2: 'Visit your dashboard on the web and do so!',

  // Login
  login: 'Login',
  logincontinue: 'Login to continue',
  email: 'Email',
  password: 'Password',
  forgotpassword: 'Forgot Password?',
  forgotyourpassword: 'Forgot your Password?',
  validemail: 'Please enter a valid email',
  emailrequired: 'Email is required',
  shortpassword: 'That password is too short',
  passwordrequired: 'Password is required',
  newtofapshi: 'New to Fapshi? Sign up',
  userconfirmation: 'User Confirmation',

  // Signup
  signup: 'Sign up',
  agreement: 'By checking this box, you agree that you have read our',
  privacypolicy: 'Privacy Policy',
  termsandconditions: 'Terms and Conditions',
  haveanaccount: 'Have an account? Login',
  verifyyouremail: 'Verify your Email',
  passlength: 'At least 6 characters long',
  confirmpass: 'Retype your password',
  namerequired: 'Your name is required',
  termserror:
    'You must approve you have read and accept our terms and privacy policy by checking this box.',
  verificationsuccess: 'Email verified successfully, now login.',
  sendcode: 'Send code',
  verificationemail:
    'Enter the email you used to sign up. We will send you a verification code.',
  signupverification: 'Sign up Verification',
  enteremail: 'Enter your email here',
  username: 'Username',
  enterusername: 'Enter a username',
  usernamerequired: 'Username is required',

  // Two Step
  entercorrectcode: 'Please enter the correct code',
  verificationcode:
    "A verification code has been sent to your email. Please check your Spam/Junk folder if you can't find it in your inbox.",
  entercode: 'Please enter the code here',

  //notifications
  notifications: 'Notifications',
  notification: 'Notification',
  recentnotifications: 'Recent Notifications',
  clearall: 'Clear all',
  fetchingnotifications: 'Fetching Notifications',
  deleting: 'Deleting',
  notificationcleared: 'Notifications cleared successfully!',
  unabletoclear: 'Unable to clear notifications. Please try again!',
  nonewnotifications: 'No New Notifications',
  nonotificationstext:
    'When transactions are made, their notifications will be shown here.',
  allfeminine: 'all',
  allmasculine: 'all',

  // Profile
  profile: 'Profile',
  editdetails: 'Edit Details',
  notall: 'Not all information can be edited.',
  supportpin: 'Support Pin',
  forgotyourfapshipin: 'Forgot your Fapshi pin?',
  reportaproblem: 'Report a problem',
  rateapp: 'Rate the App',
  shareapp: 'Share this App',
  sharemessage:
    'Hi, I use Fapshi, a set of tools that helps you to collect payments easily. Join me in downloading the app.😊\n\nAndroid 👇🏾\nhttps://play.google.com/store/apps/details?id=com.fapshi\n\niPhone 👇🏾\nhttps://apps.apple.com/ng/app/fapshi/id6443700859',
  sharetitle: 'Fapshi - Collect Payments Easily',

  //Edit details
  fullnames: 'Full names',
  typenames: 'Type in your full names',
  phonenumber: 'Phone number',
  address: 'Address',
  streetaddress: 'Enter your street address',
  update: 'Update',
  updating: 'Updating',
  updatefailed: 'Failed to update details. Try again!',
  updatesuccess: 'Details updated successfully!',
  invalidphone: 'Invalid phone number!',
  phonerequired: 'Your phone number is required!',
  eg: 'e.g.',

  // Settings
  changepassword: 'Change Password',
  createnewpassword:
    'Create a new password that is at least 6 characters long.',
  twostepauthentication: 'Two-step authentication',
  secureaccount:
    'Keep your account extra secure with a second authentication step.',
  language: 'Langue: Appuyez ici pour changer en français',
  off: 'Off',
  on: 'On',
  youhavenotactivated:
    'You have not activated your account! Tap here to visit your dashboard and do so!',
  authenticationactivated: '2SA activated successfully!',
  authenticationdeactivated: '2SA deactivated successfully!',
  nextlogin: 'The change will take effect on your next login.',

  // Balances
  fetchingbalance: 'Fetching balance',
  fetching: 'Fetching',
  method: 'Payment method',
  sentto: 'Sent to',
  fetchingbalances: 'Fetching balances',
  mainbalance: 'Main Balance',
  pendingbalance: 'Pending Balance',
  amountreceived: 'Amount Received',
  fee: 'Fee',
  payername: "Payer's Name",
  pendingtransactions: 'Pending Transactions',
  nopendingtransactions: 'You have no pending transactions.',
  your: 'Your',

  //Payment link
  fetchingpaymentlinks: 'Fetching payment links',
  createalink: 'Create a link',
  nopaymentlinks: 'No Payment Links Created',
  seehowitworks: 'See how it works',
  collectpayment:
    'You can collect payments (money) by creating and sharing a link.',
  simplelink: 'Simple Link',
  donation: 'Donation',
  donationlink: 'Donation Link',
  variablelink: 'Variable Link',
  totalrevenue: 'Total Revenue',
  donors: 'Number of donors',
  amount: 'Amount',
  revenue: 'Revenue',
  transactionid: 'Transaction ID',
  paidby: 'Paid by',
  fetchingdetails: 'Fetching details',
  at: 'at',
  onon: 'on',
  nopayments: 'No Payments Yet',
  nopaymentstext: "When you receive payments, they'll appear here.",
  deleted: 'Deleted successfully!',
  unabletodelete: 'Unable to delete. Try again!',
  share: 'Share',
  preview: 'Preview',
  viewpayments: 'View Payments',
  edit: 'Edit',
  delete: 'Delete',

  //Simple link
  createsimplelink: 'Create a Simple Link',
  editlink: 'Edit Link',
  updatelink: 'Update Link',
  linktitle: 'Link Title',
  enterlinktitle: 'Enter a title for the link',
  selectdate: 'Select Due Date',
  taphere: 'Tap here to select',
  description: 'Description',
  enterdescription: 'Enter a description',
  amountpaid: 'Amount to be paid',
  createlink: 'Create Link',
  processing: 'Processing',
  linkcreated: 'Payment link created successfully!',
  linkupdated: 'Payment link updated successfully!',
  failedlinkupdate: 'Failed to edit payment link. Try again!',
  failedlinkcreate: 'Failed to create payment link. Try again!',
  titlerequired: 'A title is required',
  providedescription: 'Please provide a description',
  theamount: 'The amount cannot be less than 500 ',
  youcannotreceive: 'You cannot receive payments above 10,000,000 ',
  insertamount: 'Please insert an amount',

  //donation link
  createdonationlink: 'Create a Donation Link',
  donordecideamount: 'Let the donor decide the amount?',
  no: 'No',
  yes: 'Yes',
  contactnumber: 'Contact Number',
  website: 'Website',
  showdonations: 'Show Donations?',
  theminimumamount: 'The minimum amount cannot be less than 500 ',
  thecontactnumber: 'The contact number must be at least 9 digits long',
  setamount: 'Please set an amount',
  minimumamount: 'Minimum amount (Optional)',
  defaultsto: 'defaults to 500 FCFA',
  optional: '(optional)',

  //variable link
  createvariablelink: 'Create a variable link',
  amountlabel: 'Amount Label',
  vip: 'VIP',
  premium: 'Premium',
  saveamount: 'Save amount',
  pricedetails: 'Price Details',
  removeamount: 'Remove amount',
  provideamount: 'Please provide an amount',
  provideamountlabel: 'Please provide an amount label',

  //Events
  eventcreated: 'Event created successfully!',
  eventupdated: 'Event updated successfully!',
  failedeventupdate: 'Failed to edit the event. Please, try again!',
  failedeventcreate: 'Failed to create the event. Please, try again!',
  categoryerror: 'Please set at least one ticket category',
  priceerror: "Set the ticket's price",
  unabletofetchdetails:
    "We're unable to fetch event details at the moment. Please try again!",

  //invoices
  createinvoice: 'Create an invoice',
  noinvoicecreated: 'No Invoice Created',
  sendinvoice: 'Send invoices to your clients and get paid with proof.',
  simpleinvoice: 'Simple Invoice',
  advancedinvoice: 'Advanced Invoice',
  fetchinginvoice: 'Fetching Invoice',
  send: 'Send',
  sending: 'Sending',
  resend: 'Resend',
  resending: 'Resending',
  remindersent: 'Reminder sent successfully!',
  unabletoremind: 'Unable to send reminder. Try again!',
  invoiceresent: 'Invoice resent successfully!',
  unabletoresend: 'Unable to resend invoice. Try again!',
  unabletofetchinvoice: 'Unable to fetch invoice details!',
  requestfailed: 'Request failed!',
  anerroroccured:
    'An error occurred! Please check your internet connection and try again.',
  unabletoprocess: 'Unable to process request! Please try again.',
  duplicate: 'Duplicate',
  sendreminder: 'Send a reminder',
  createdon: 'Created on',
  paidon: 'Paid on',

  // Simple Invoice
  provideclientname: "Please provide the client's name",
  provideclientemail: "Please provide the client's email",
  phonemin: 'The phone number must be at least 9 digits long',
  typenote: 'Type in a note for your client',
  invoiceedited: 'Invoice edited successfully!',
  invoicecreated: 'Invoice created successfully!',
  invoiceeditfailed: 'Failed to edit invoice. Try again!',
  invoicecreatefailed: 'Failed to create invoice. Try again!',
  editinvoice: 'Edit Invoice',
  createsimpleinvoice: 'Create a Simple Invoice',
  clientname: "Client's Name",
  enterclientname: "Enter the client's name",
  clientemail: "Client's Email",
  enterclientemail: "Enter the client's email",
  clientphone: "Client's Phone Number (Optional)",
  clientphonenumber: "Client's Phone Number",
  note: 'Note',
  updateinvoice: 'Update Invoice',
  saveinvoice: 'Save & Send invoice',

  // Advanced Invoice
  createadvancedinvoice: 'Create an Advanced Invoice',
  optionaltexts: 'The fields below are optional',
  deliveryfee: 'Delivery fee',
  enterclientlocation: "Enter your client's location",
  invaliddeliveryfee: 'Invalid delivery fee',
  items: 'Items',
  item: 'Item',
  enteritemname: 'Enter the item name',
  quantity: 'Quantity',
  unitprice: 'Unit price',
  subtotal: 'Subtotal',
  total: 'Total',
  additem: 'Add item',
  removeitem: 'Remove item',
  provideitem: 'Please provide an item name',
  providequantity: 'Please provide a quantity',
  provideunitprice: 'Please provide the unit price',

  //Payouts
  recenttransactions: 'Recent Transactions',
  fetchingrecenttransactions: 'Fetching recent transactions',
  norecenttransactions:
    'You have no recent transactions. Make a payout and it will appear here.',
  requestbank: 'Request a Bank Transfer',
  withdrawmomo: 'Withdraw via MTN MoMo',
  notsetpin: 'You have not set a pin for payouts!',
  setpin: 'Set Pin',
  checkingtransactions:
    'Please wait while we check for any new transactions...',

  //withdraw with mtn momo
  yourpin: 'Your Pin',
  enterfapshipin: 'Enter your Fapshi pin',
  payout: 'Payout',
  withdrawmin: 'You cannot withdraw less than 100 ',
  withdrawmax: 'You cannot withdraw more than 100,000 ',
  withdrawmaxcont: ' through MoMo, please request a bank transfer.',
  aphonerequired: 'A phone number is required',
  pinshort: 'Your pin is short',
  cantexceed: "Can't exceed 5 digits",
  insertpin: 'Please insert your pin',
  processingpayout: 'Processing payout',
  balanceinsufficient: 'Your balance is insufficient',
  payoutsuccess: 'Payout successful!',
  setpinpayout: 'Please set a pin for payouts',
  amounttowithdraw: 'Amount to withdraw',
  entermomonumber: 'Enter the MoMo number',
  momonumber: 'MoMo Number',

  // bank transfer request
  bankdetails: 'Bank Details',
  addbankaccount: 'Add a Bank Account',
  editbank: 'Edit Bank Details',
  sendrequest: 'Send Request',
  cannotwithdrawmin: 'You cannot withdraw less than 100,000 ',
  cannotwithdrawmincont: ' through bank transfer. Please payout through MoMo.',
  cannotwithdrawmax: 'You cannot withdraw more than 10,000,000 ',
  cannotwithdrawmaxcont: ' through a single request.',
  processingpayoutrequest: 'Processing payout request',
  fetchingbank: 'Fetching bank details',
  havenotaddedbank: 'You have not added a bank account for payouts!',
  enteramount: 'Enter the amount',
  yourbankdetails: 'Your Bank Details',
  bankname: 'Bank Name',
  accountnumber: 'Account Number',
  accountholder: 'Account Holder',
  bankcode: 'Bank Code',
  branchcode: 'Branch Code',
  bankkey: 'Bank Key',
  accountnamerequired: 'The account name is required',
  banknamerequired: 'Please select a bank',
  bankcoderequired: 'The bank code is required',
  branchcoderequired: 'Please enter the branch code',
  accountnumberrequired: 'Please enter your account number',
  bankkeyrequired: 'The bank key is required',
  selectbank: 'Select a bank',
  search: 'Search',
  enteraccountnumber: 'Enter your account number',
  enteraccountholder: 'Enter the name exactly as on your account',
  accountholdername: "Account Holder's Name",
  enterbankcode: 'Enter the bank code',
  enterbranchcode: 'Enter the branch code',
  enterbankkey: 'Enter the bank key',
  save: 'Save',
  contactbank: 'Contact your bank to get the details below.',

  // Bottom Sheet
  providecurrentpassword: 'Please provide your current password',
  providenewpassword: 'Please provide your new password',
  passdontmatch: 'Your passwords do not match',
  changepasssuccessful: 'Password changed successfully!',
  currentpassword: 'Current password',
  typecurrentpassword: 'Type in your current password',
  newpassword: 'New password',
  typenewpassword: 'Type in your new password',
  confirmpassword: 'Confirm password',
  typeconfirmpassword: 'Type in your new password again',

  // Modals
  cancel: 'Cancel',
  submit: 'Submit',
  confirm: 'Confirm',
  yesdelete: 'Yes, delete',
  nocancel: 'No, cancel',
  areyousure: 'Are you sure you want to ',
  thisfeminine: 'this',
  thismasculine: 'this',
  payouttext:
    'will be withdrawn from your Fapshi account and sent to the MTN MoMo account on this number:',
  bankpayouttext:
    'will be withdrawn from your Fapshi account and sent to your bank account.',
  presssend: 'Press "Send" to confirm or "Cancel" to cancel this transaction.',
  confirmbankdetails: 'Please confirm your bank details below',
  submitting: 'Submitting',
  verificationrequired: 'Verification Required',
  verificationtext:
    'Some actions require extra verification. Please confirm your password to proceed.',
  enterpassword: 'Enter your password',
  invalidpassword: 'Invalid password!',
  verify: 'Verify',
  setyourpin: 'Set Your Fapshi Pin',
  setpintext:
    'You can only set your pin once. Should you need to change it, please',
  pinlength: 'Your pin must be exactly five digits long!',
  pinsuccess: 'Pin set successfully!',
  enterfive: 'Enter five digits',
  successfulpayout: 'You have successfully withdrawn',
  successfulpayouttext:
    'from your Fapshi account to the MTN MoMo account on this number:',
  done: 'Done',
  payoutfailed: 'Payout Failed!',
  payoutfailedreason: 'Your request failed for this reason:',
  ok: 'OK',
  pleasetryagain: 'Please try again!',
  successfulbankpayout: 'Your request to withdraw',
  successfulbankpayouttext:
    "from your Fapshi account was successful. You will be notified once the transfer is complete; this shouldn't exceed 48 hours.",
  addbanksuccess: 'Bank Details Added!',
  addbanksuccesstext:
    'Your bank details have been successfully added! All bank payout withdrawals will be sent to this account. Should you need to change it, please',
  themasculine: 'the',
  thefeminine: 'the',
  theplural: 'the',
  unabletologout: 'Unable to logout',

  // Delete Account
  deletemsg:
    'If you delete your account, you will not be able to use this same email to create another one.',
  deletenote:
    'NB: Make sure you withdraw any account balance before proceeding.',
  deleteaccount: 'Delete Account',
  deleteyouraccount: 'Delete your account',
  unabletodeleteaccount: 'Unable to Delete Account',
  successfulaccountdelete: 'Account deleted successfully!',
  deleteirreversible:
    'This action is irreversible. Input your password to proceed.'
};

export default texts;
